import {
  atomsERMLabelObjs,
  atomsLabelObjs,
  catcherMittBackofBeltBackStylePartsObjs,
  catcherMittBackofRegularBackStylePartsObjs,
  catcherMittBackPartsObjs,
  catcherMittCatchFaceObjs,
  catchFacePartsObjs,
  dispatchNineStatesObjectsJa,
  dispatchNorthSteerObjectsJa,
  dispatchObjects,
  dispatchPremiumSteerObjectsJa,
  dispatchSouthSteerObjectsJa,
  firstMittBackPartsNoBeltObjs,
  firstMittBackPartsObjs,
  gloveBeltBackPartsNoBeltObjs,
  gloveBeltBackPartsObjs,
  gloveCrownBackPartsObjs,
  gloveFirstBackPartsObjs,
  gloveUsBackPartsObjs,
  hamidashiAllObjs,
  hamidashiForCatcherObjs,
  hamidashiObjs,
  infielderBackPartsForUBackObjs,
  laceColorObjs,
  leatherColorObjesByTanner,
  leatherColorObjsJa,
  listLiningsMaterialObjs,
  stitchColorObjs
} from 'src/features/brands/atoms/japan/constants/colors'
import {
  backLaceObjs,
  backStyleForCatcherMittObjs,
  backStyleForPitcherObjs,
  backStyleObjs,
  dominantArmObjs,
  fingerGuardTypeAllObjects,
  fingerGuardTypeForOutfielderObjs,
  fingerGuardTypeObjs,
  loopOfRingFingerObjs,
  materialPackObjs,
  moldingJaObjs,
  sizeObjs
} from 'src/features/brands/atoms/japan/constants/bases'
import { colorObjs, positionObjs, shadowEdgeColorObjs, typeFaceObjs } from 'src/features/brands/atoms/japan/constants/embroidery'
import { initialStateCatcherMitt } from 'src/features/brands/atoms/japan/reducer/catcher'
import { initialStateFirstMitt } from 'src/features/brands/atoms/japan/reducer/first'
import { initialInfielderState } from 'src/features/brands/atoms/japan/reducer/infielder'
import { initialOutfielderState } from 'src/features/brands/atoms/japan/reducer/outfielder'
import { initialPitcherState } from 'src/features/brands/atoms/japan/reducer/pitcher'
import { leagueOptions, positionJaOptions } from 'src/locales/constants'
import { initialYouthState } from 'src/features/brands/atoms/japan/reducer/youth'
import { tannerObjs } from 'src/features/brands/atoms/us/constants/colors'
import { initialUsGloveState } from 'src/features/brands/atoms/us/reducer/glove'
import { glovePriceTaxIncluded, mittPriceTaxIncluded } from 'src/features/brands/atoms/japan/constants/baseModel'

export default {
  SIMULATABLE_BRANDS: 'シミュレーション可能なブランド',
  ORDER_SIMULATION: 'オーダーシミュレーション',
  IF_START_SIMULATION: `※シミュレーションを開始した場合、`,
  IF_START_SIMULATION_2: `利用規約に同意したものとみなします。`,
  START_BUTTON: 'シミュレーションを開始',
  CHECK_SIMULATION: 'シミュレーションを確認',
  TERMS_OF_USE: 'ご利用規約',
  TERMS_OF_USE_ITEM_1: '・本システムは、ATOMSグループのオーダーグラブのシュレミーションができることを目的にデザインされております。',
  TERMS_OF_USE_ITEM_2: '・一部デザインやカラーが実際の商品と異なる場合があります。',
  TERMS_OF_USE_ITEM_3: '・高校野球など、連盟ごとに使用制限がありますので各自ご確認下さい。',
  TERMS_OF_USE_ITEM_4: '・シミュレーション時に発行されるグラブIDの不正使用について、当社は一切の責任を負いません。',
  TERMS_OF_USE_ITEM_5: '・利用されたお客様のプライバシーを守るため、暗号化等の方法により、情報の保護に努めます。',
  TERMS_OF_USE_ITEM_6:
    '・本システムの利用は、お客様の責任において行われるものとします。当該システムおよび当該システムに非リンクされている他のウェブサイトから取得された各種情報の利用によって生じたあらゆる損害に関して、弊社は一切の責任を負いません。',
  TERMS_OF_USE_ITEM_7: '・当社システムは適宜見直しを行い、改訂することがあります。',
  TRANSLATE_CAUTION_DESCRIBE: '韓国語でシステムを引き続き利用する場合、Googleの自動翻訳機能をOFFにすることを推奨します。',
  FOR_DETAIL: '詳しくはこちら↓',
  HOW_TO_OFF_GOOGLE_AUTO_TRANSLATE: 'Google Chromeの自動翻訳機能をOFFにする方法',
  DISABLE_MESSAGE_EVER: '今後このメッセージを表示しない',
  CLOSE: '閉じる',
  PRODUCT_PAGE: '製品ページ',
  HARD_GLOVE_MITT: '硬式グローブ・ミット',
  HARD: '硬式',
  SOFT_BALL_GLOVE_MITT: '軟式グローブ・ミット',
  SOFT: '軟式',
  SPIKES: 'スパイク',
  BAT: 'バット',
  GLOVE: 'グローブ',
  MITT: 'ミット',
  COMMING_SOON: '(準備中)',
  BRAND_SELECTION_PAGE: 'ブランド選択ページ',
  POSITION_SELECTION_PAGE: 'ポジション',
  SERIES_SELECTION_PAGE: 'シリーズ選択ページ',
  PITCHER: '投手用',
  CATCHER: '捕手用',
  INFIELDER: '内野手用',
  OUTFIELDER: '外野手用',
  FIRST_BASEMAN: '一塁手用',
  JAPAN_SERIES: '日本モデル',
  USA_SERIES: 'アメリカンモデル',
  KOREA_SERIES: '韓国モデル',
  PLEASE_SELECT_MODEL: '基本モデルを選択してください',
  PRODUCT_NUMBER: '型番：',
  START_SIMULATION: 'シミュレーションを開始',
  // ATOMS_投手用
  AKG_1: { description: 'オーソドックスな投手用。ポケットは深めで横ふりモデル。', size: 30.0 },
  AKG_11: { description: '小ぶりの投手用。フィールディングにも優れる縦型モデル。', size: 29.0 },
  AKG_21: { description: 'ATOMS投手用初の縦型・縦綴じ採用グラブ', size: 29.8 },
  AKG_PRO46: { description: 'AGL-101と同様で、プロ選手型', size: 29.0 },
  ATR_001: { description: '深めで縦型、しっかり掴める投手用。', size: 29.0 },
  US_PITCHER: { description: 'ポケットが深いタイプ。深めで縦型、しっかり掴める投手用。', size: 0 },
  // ATOMS_内野手用
  AKG_JS4_PLUS: { description: '変幻自在にどんな打球にも対応できるモデル。', size: 28.7 },
  AKG_JS5: { description: '土手は狭くしっかり掴んで捕るプレイヤー向け。', size: 28.3 },
  AKG_16: { description: 'ATOMS最軽量設計。指がシャープで操作性に優れ、ゴロがさばきやすいモデル。', size: 27.8 },
  AKG_4: { description: '変幻自在にどんな打球にも対応できるモデル。', size: 28.0 },
  AKG_5: { description: '現代野球スタイルの超スタンダード。選手のプレースタイルに型が馴染みやすいモデル。', size: 28.3 },
  AKG_6: { description: '内野手用で唯一「レギュラー巻き」を採用。手口のワイドさを軽減したモデル', size: 29.6 },
  APL_UR2: { description: '横振りにも使える深いグラブ。しっかりと掴む二遊間向けモデル。', size: 29.0 },
  APL_UR3: { description: '最も浅いグラブ。試合用でもトレーニング用でも対応できるモデル。', size: 27.5 },
  APL_UR4: { description: '深くなりにくい設計。捕球面を広く使いたいプレイヤー向け', size: 28.0 },
  APL_UR5: { description: 'ショート、サード向けの大きさでウェブ周りは強い打球にも負けない設計。', size: 28.3 },
  APL_UR10: { description: 'Uバックにエッジ付きウェブ採用モデル。', size: 28.0 },
  APL_UR026_PLUS: { description: '操作性を重視した内野手用。捕球から送球への流れがスムーズに行えるモデル', size: 28.3 },
  APL_UR004_PLUS: { description: 'Uバック採用の捕球面やや広めのモデル', size: 28.3 },
  APL_UR005_PLUS: { description: '捕球時、しっかりと掴むタイプ。強い打球にも負けない。ショート・サード向き', size: 28.3 },
  APL_UR006_PLUS: { description: 'ATR-006+後継モデル。プロ野球選手も使用する型でやや大きめのBOX型。', size: 29.5 },
  APL_UR015_PLUS: { description: '深めでしっかり包み込むタイプの型。', size: 29.0 },
  APL_UR066_PLUS: { description: 'NEWモデル。AKG-6型を少し小ぶりにし、 操作性を高くしたモデル。', size: 29.0 },
  AGL_105: { description: '投手兼オールラウンドタイプ。', size: 29.0 },
  AGL_501: { description: '小さめの内野手用。手口も小さめ。ユースにも対応できるタイプ。', size: 27.8 },
  AGL_503: { description: '深く広く使えるタイプの内野手用モデル', size: 28.6 },
  AGL_601: { description: '内野手用の中では大きめなタイプのボックス型です。', size: 29.6 },
  AGL_603: { description: 'ATOMSでは少ない横ぶりの内野手用モデル。小さめでポケットを 作りやすく、逆シングルにも対応しやすいタイプ。', size: 29.3 },
  ATR_006: { description: 'ポケットは横に広く、当て捕りも掴みもできるタイプ。', size: 29.5 },
  US_INFIELDER: { description: '深めの設計。ボールを掴む感覚を重視するプレイヤー向けモデル', size: 0 },
  // ATOMS_外野手用
  AKG_7: { description: '指がシャープな縦型。ポケットが狭く、ボールの遊びが少ないため、次の動作へのブレが少ないモデル。', size: 31.5 },
  AKG_JS7: { description: 'シャープな縦型でポケットは深めのモデル。', size: 32.0 },
  AKG_GT07: { description: 'NEWモデル。ポケットは深くしっかり捕れるタイプ。', size: 33.0 },
  AKG_17: { description: 'ポケットは深めで広く、しっかり包み込むタイプのモデル。', size: 32.8 },
  AKG_27: { description: '外野手用としては小さめ。ポケット深めの設計で ソフトボールに対応できるタイプ。', size: 31.5 },
  ATR_007: { description: 'ポケットは深く、強い打球にも負けない外野手用。', size: 32.5 },
  AGL_801: { description: '小ぶりな外野手用。軽量かつポケットが深いタイプ。', size: 30.5 },
  APL_UR6: { description: '小さめながらしっかり掴めるモデル。', size: 31.5 },
  APL_UR007_PLUS: { description: 'ポケットは深く、強い打球にも負けない外野手用。', size: 32.5 },
  APL_UR008_PLUS: { description: 'NEWモデルAKG-GT07同型のERMリストタイプ。', size: 33.0 },
  US_OUTFIELDER: { description: 'ポケットは深く、強い打球にも負けない外野手用', size: 0 },
  // ATOMS_捕手用
  AKG_12: { description: '小ぶりで操作性も高く使いやすいモデル', size: 32.5 },
  AKG_JS2: { description: '浅めでボールの握り替えがスムーズに出来るモデル。ウェブは硬い設計。', size: 31.5 },
  APL_UR002_PLUS: { description: 'レギュラーバックとERMリストにより操作性抜群', size: 31.5 },
  AKG_GT22: { description: 'NEWモデル。レギュラーバック採用ミット。', size: 32.5 },
  // ATOMS_一塁手用
  AKG_13: { description: '操作性も高い小さめのモデル', size: 32.5 },
  APL_UR003_PLUS: { description: '小型の一塁手用グラブのような操作性', size: 32.5 },
  AKG_GT33: { description: 'NEWモデル。しっかり捕球できる新型ウェブ採用', size: 32.5 },
  DRAWING_CAUTION: '※グラブ・ミットの描画、刺繍の位置・書体はイメージであり、実際とは異なる場合があります。',
  CATCH_SURFACE: '捕球面',
  BACK_SURFACE: '背面',
  SELECTED_BASE_MODEL: '選択中の基本モデル',
  TO_TOP: 'トップへ',
  RESET: 'リセット',
  SEARCH: '検索',
  CONFIRM: '確認',
  CONFIRM_TO_TOP: '本当にトップページに移動しますか？',
  CONFIRM_RESET: `本当にリセットしますか？\n全ての設定がリセットされます`,
  CONFIRM_SEND_EMAIL: `データを保存し、メールアドレス先に保存IDを送付します。`,
  CONFIRM_START_SIMULATION: ` を元にシミュレーションを開始します。`,
  PARTS_SETTING: 'パーツ設定',
  COLOR_SETTING: 'カラー設定',
  EMBROIDERY_SETTING: '刺繍設定',
  BASE_MODEL: '基本モデル',
  KINDS: '種別',
  TANNER: 'タンナー',
  DOMINANT_ARM: '利き腕',
  BACK_STYLE: 'バックスタイル',
  MATERIAL_PACK: '芯とじスタイル',
  FINGER_SIZE: '指の長さ',
  BACK_LACE: '背面紐通し',
  WEB: 'ウェブ',
  WEB2: 'ウェブ2',
  FINGER_GUARD: '指カバー/指あて',
  MOLDING_ORDER: 'オリジナル型付け',
  FINGER_TIP_OUT: '指だし',
  STAND: '背面台',
  TARGET: 'ターゲット',
  PARTS: 'パーツ',
  COLOR: ' カラー',
  LACE: 'レース',
  STITCH: 'ステッチ',
  HAMIDASHI: 'ハミダシ',
  EDGE: 'ヘリ革',
  THUMB_HOOK: '親指掛け紐',
  LITTLE_HOOK: '小指掛け紐',
  LININGS: '平裏',
  MOUTON: 'ムートン',
  LABEL: 'ラベル',
  THUMB: '親指',
  THUMB1: '親指1',
  THUMB_INDEX_MIDDLE5: '親指・人差指・中指5',
  THUMB2: '親指2',
  THUMB_ROOT: '親指根本',
  INDEX: '人差指',
  INDEX3: '人差指3',
  INDEX4: '人差指4',
  MIDDLE: '中指',
  MIDDLE5: '中指5',
  MIDDLE6: '中指6',
  RING: '薬指',
  RING7: '薬指7',
  RING8: '薬指8',
  LITTLE: '小指',
  LITTLE9: '小指9',
  LITTLE10: '小指10',
  THUMB2_INDEX3: '親指2・人差指3',
  THUMB2_INDEX3_MIDDLE4: '親指2・人差指3・中指4',
  THUMB2_INDEX_MIDDLE5: '親指2・人差指3,4・中指5',
  THUMB2_INDEX_MIDDLE_RING7: '親指2・人差指3,4・中指5,6・薬指7',
  INDEX4_MIDDLE5: '人差指4・中指5',
  MIDDLE5_RING: '中指5・薬指',
  MIDDLE5_RING6: '中指5・薬指6',
  MIDDLE6_RING7: '中指6・薬指7',
  MIDDLE6_RING_PINKY: '中指6・薬指・小指',
  RING7_LITTLE8: '薬指7・小指8',
  RING8_LITTLE9: '薬指8・小指9',
  BELT: 'ベルト',
  BAND: 'バンド',
  UNDER_WEB: 'ウェブ下',
  BOOMERANG: 'ブーメラン',
  LOOP_OF_RING: '薬指リング',
  POSITION: '位置',
  TYPE_FACE: '書体',
  MAIN_COLOR: '刺繍カラー',
  POINT_COLOR: 'ポイントカラー',
  SHADOW_COLOR: '影カラー',
  EDGE_COLOR: 'フチカラー',
  EMBROIDERY_CONTENTS: '刺繍内容',
  ENTER_EMBROIDERY_CONTENTS: '刺繍内容を入力してください',
  MAX_NUMBER_OF_CHAR: '最大文字数',
  CHARACTERS: '文字',
  BASE_ITEMS: '基本項目',
  COLOR_ITEMS: 'カラー項目',
  EMBROIDERY_ITEMS: '刺繍項目',
  NO_EMBROIDERY: '刺繍なし',
  BASE_PRICE: '基本金額：',
  EMBROIDERY_PRICE: '刺繍金額：',
  OPTION_PRICE: 'オプション金額：',
  TOTAL_PRICE: '合計金額：',
  MONEY_UNIT: '円',
  SIZE_UNIT: ' cm',
  COPY: 'コピー',
  COPIED: 'コピー済',
  SAVED_ID: '保存ID',
  SAVE_CAUTION: 'シミュレーション内容は、こちらの画面をスクリーンショット等で、予め保存いただきますようお願いいたします。',
  CUSTOMER_INFORMATION: 'お客様情報',
  CUSTOMER_INFORMATION_CAUTION: '項目を全て選択後、お客様情報をご入力いただけます。',
  DUPLICATE_EMBROIDERY_POSITION_CAUTION: '刺繍位置が重複しています。重複が無いよう再度ご確認ください。',
  LEAGUE: 'リーグ',
  FIELD_POSITION: '守備位置',
  EMAIL_ADDRESS: 'メールアドレス',
  EMAIL_ADDRESS_RETYPE: 'メールアドレス(再入力)',
  SAVE_BUTTON: '保存',
  NOW_SAVING: '保存中...',
  RETAIL_LOGIN_PAGE_HEADER: '小売店用ログイン画面',
  RETAIL_LOGIN_PAGE_EXPLAIN: '小売店員の方はログインの上、お客様のシミュレーション結果をIDで検索をしてください',
  LOGIN: 'ログイン',
  NOW_LOGING: 'ログイン中...',
  NOW_LOADING: '読み込み中...',
  BACK_TO_TOP: 'トップへ戻る',
  PASSWORD: 'パスワード',
  RESET_PASSWORD: 'パスワードをリセット',
  PAGE_OF_RESET_PASSWORD: '小売店用パスワードリセット画面',
  DESCRIPTION_OF_HOW_TO_RESET_PASSWORD: '該当のメールアドレスを入力し、送信ボタンを押してください。',
  SEND_RESET_EMAIL: 'リセットメールを送信',
  TO_RETAIL_LOGIN_PAGE: '小売店ログイン画面へ',
  CAUTION_SENT_RESET_EMAIL: '送信しました。受信されない場合、迷惑メール扱いになっていないかもご確認ください。',
  SELECT_BRAND: 'ブランドを選択',
  SIMULATION_ID: 'シミュレーションID',
  ENTER_ID: 'IDを入力',
  LOGOUT: 'ログアウト',
  RETAIL_CAUTION_1: 'このブランドは取扱対象外なため、検索できません。',
  RETAIL_CAUTION_2: '検索されたシミュレーションIDは、選択されたブランドでは見つかりませんでした。正しいブランドを選択してください。',
  NAME: 'お名前：',
  TEL: 'お電話：',
  REMARK: '備考欄：',
  CUSTOMER_FORM_CAUTION_1: '※料金は予告なく変更される可能性があります。',
  CUSTOMER_FORM_CAUTION_2: '※備考欄に記載いただいたご要望にお応えできない場合がございます。',
  CUSTOMER_FORM_CAUTION_3: '※特別なご要望等は、お手数ですが代理店担当者にもお問い合わせください。',
  TANNER_OBJECTS: tannerObjs,
  DOMINANT_ARM_OBJECTS: dominantArmObjs,
  BACK_STYLE_OBJECTS: backStyleObjs,
  BACK_STYLE_FOR_PITCHER_OBJECTS: backStyleForPitcherObjs,
  BACK_STYLE_FOR_CATCHER_OBJECTS: backStyleForCatcherMittObjs,
  FINGER_GUARD_TYPE_DESCRIPTION: 'バックスタイルを先に選択してください。',
  MATERIAL_PACK_OBJECTS: materialPackObjs,
  SIZE_OBJECTS: sizeObjs,
  MOLDING_OBJECTS: moldingJaObjs,
  LOOP_OF_RING_FINGER_OBJECTS: loopOfRingFingerObjs,
  LOOP_OF_RING_FINGER_DESCRIPTION: 'オプション：3,300円, ※カラーは裏平と同色になります。',
  BACK_LACE_OBJECTS: backLaceObjs,
  FINGER_GUARD_TYPE_FOR_OUTFIELDER_OBJECTS: fingerGuardTypeForOutfielderObjs,
  FINGER_GUARD_TYPE_OBJECTS: fingerGuardTypeObjs,
  FINGER_GUARD_TYPE_ALL_OBJECTS: fingerGuardTypeAllObjects,
  CAUTION_TANNER: '※一部パーツのカラーに適用されます',
  CAUTION_DOMINANT_ARM: '',
  CAUTION_SIZE: 'サイズ変更はオプション料金が発生します。',
  CAUTION_MOLDING_ORDER: 'オプション: 5,500円',
  // CAUTION_MATERIAL_PACK: '「捕球面」の土手の形状が変わります。',
  CAUTION_LACE: '中白系カラーは断面が白色です。シュミレーションには反映されません。',
  CAUTION_BACK_STYLE: '変更するためには、指カバーを一度"無し"にしてください。',
  LACE_COLOR_OBJECTS: laceColorObjs,
  EDGE_COLOR_OBJECTS: leatherColorObjsJa,
  STITCH_COLOR_OBJECTS: stitchColorObjs,
  HAMIDASHI_COLOR_OBJECTS: hamidashiObjs,
  HAMIDASHI_COLOR_ALL_OBJECTS: hamidashiAllObjs,
  HAMIDASHI_COLOR_FOR_CATCHER_OBJECTS: hamidashiForCatcherObjs,
  MOUTON_COLOR_OBJECTS: listLiningsMaterialObjs,
  LABEL_COLOR_OBJECTS: atomsLabelObjs,
  ERM_LABEL_COLOR_OBJECTS: atomsERMLabelObjs,
  LEATHER_COLOR_OBJECTS_BY_TANNER: leatherColorObjesByTanner,
  DISPATCH_COLOR_OBJECTS: dispatchObjects,
  DISPATCH_PREMIUM_LEATHER_COLOR_OBJECTS: dispatchPremiumSteerObjectsJa,
  DISPATCH_NORTH_LEATHER_COLOR_OBJECTS: dispatchNorthSteerObjectsJa,
  DISPATCH_US_LEATHER_COLOR_OBJECTS: dispatchObjects,
  DISPATCH_SOUTH_LEATHER_COLOR_OBJECTS: dispatchSouthSteerObjectsJa,
  DISPATCH_NINE_STATES_LEATHER_COLOR_OBJECTS: dispatchNineStatesObjectsJa,
  CATCH_FACE_OBJECTS: catchFacePartsObjs,
  CATCH_FACE_FOR_CATCHER_OBJECTS: catcherMittCatchFaceObjs,
  // 背面パーツここから
  FIRST_BACK_OBJECTS: gloveFirstBackPartsObjs,
  CROWN_BACK_OBJECTS: gloveCrownBackPartsObjs,
  BELT_BACK_OBJECTS: gloveBeltBackPartsObjs,
  US_MODEL_BACK_OBJECTS: gloveUsBackPartsObjs,
  UBACK_OBJECTS: infielderBackPartsForUBackObjs,
  NO_BELT_BACK_OBJECTS: gloveBeltBackPartsNoBeltObjs,
  C_MITT_REGULAR_BACK_OBJECTS: catcherMittBackofRegularBackStylePartsObjs,
  C_MITT_BELT_BACK_OBJECTS: catcherMittBackofBeltBackStylePartsObjs,
  C_MITT_BACK_OBJECTS: catcherMittBackPartsObjs,
  F_MITT_NO_BELT_BACK_OBJECTS: firstMittBackPartsNoBeltObjs,
  F_MITT_BACK_OBJECTS: firstMittBackPartsObjs,
  EMBROIDERY_POSITION_OBJECTS: positionObjs,
  EMBROIDERY_TYPEFACE_OBJECTS: typeFaceObjs,
  EMBROIDERY_COLOR_OBJECTS: colorObjs,
  EMBROIDERY_SHADOW_EDGE_COLOR_OBJECTS: shadowEdgeColorObjs,
  CAUTION_EMBROIDERY_POSITION: '刺繍位置を変更するには、刺繍内容をリセットしてください',
  CAUTION_EMBROIDERY_TYPEFACE: '書体は実際のものと若干異なる場合があります。予めご了承ください。',
  CAUTION_EMBROIDERY_CONTENTS: '"刺繍位置" を選択してください。',
  CAUTION_EMBROIDERY_SHADOW_EDGE_COLOR: '"刺繍カラー" を選択してください。',
  DESCRIBE_EMBROIDERY_PLUS_BUTTON: '2か所刺繍ご希望の場合には「+」をクリックの上、刺繍2を表示し入力して下さい。',
  DESCRIBE_EMBROIDERY_MINUS_BUTTON: '1か所削除したい場合には、「-」をクリックしてください。',
  NO_SELECTION: '選択不可',
  INITIAL_STATE_C_MITT: initialStateCatcherMitt,
  INITIAL_STATE_F_MITT: initialStateFirstMitt,
  INITIAL_STATE_INFIELDER: initialInfielderState,
  INITIAL_STATE_OUTFIELDER: initialOutfielderState,
  INITIAL_STATE_PITCHER: initialPitcherState,
  INITIAL_STATE_US_GLOVE: initialUsGloveState,
  INITIAL_STATE_YOUTH: initialYouthState,
  TO_OTHER_LANGUAGES_PAGE: 'To English Page →',
  SENDING_MAIL: '送信中...',
  SEND_MAIL_SUCCESSFULL: '送信完了',
  LEAGUE_OPTIONS: leagueOptions,
  POSITION_OPTIONS: positionJaOptions,
  BASE_PRICE_GLOVE: glovePriceTaxIncluded,
  BASE_PRICE_MITT: mittPriceTaxIncluded,
  MAIN_COLOR_PRICE: 2420, // 税込
  SHADOW_COLOR_PRICE: 2970, // 税込
  EDGE_COLOR_PRICE: 4620, // 税込
  AHN_JAE_SEOK_MODEL: { description: '', size: 0, productNumber: 'アン・ジェソク モデル' },
  SHIN_JUNG_WOO_MODEL: { description: '', size: 0, productNumber: 'シン・ジュンウ モデル' },
  PARK_GE_BUM_MODEL: { description: '', size: 0, productNumber: 'パク・ゲボム モデル' },
  HAFN_DONG_HEE_MODEL: { description: '', size: 0, productNumber: 'ハン・ドンヒ モデル' },
  LANGUAGE: '言語',
  WEB2_PARTS_OBJECT: { value: 'web2', label: 'ウェブ2', order: 3 },
  EMBROIDERY_CONTENT_EMOJI_CAUTION: '絵文字・記号は⭐︎,❤️,♯,🎵のみ刺繍可能です。'
}
